import { lazy } from 'react';

import ErrorPage from 'common/components/konectComponents/ErrorPage';
import ExpiredPage from 'common/components/konectComponents/ExpiredPage';
const TassDashboard = lazy(() => import('pages/tass/dashboard'));
const TassActivate = lazy(() => import('pages/tass/activate'));
const WondeDashboard = lazy(() => import('pages/wonde/dashboard'));
const WondeActivate = lazy(() => import('pages/wonde/activate'));
const SentralActivate = lazy(() => import('pages/sentral/activate'));
const SentralDashboard = lazy(() => import('pages/sentral/dashboard'));
const SpreadSheet = lazy(() => import('pages/spreadsheet'));
const SSO = lazy(() => import('pages/sso'));
const BA_SSO = lazy(() => import('pages/ba_sso'));
const BA_SSO_LOGIN = lazy(() => import('pages/ba_sso/Login'));
const BulkUpload = lazy(() => import('pages/bulkimport'));
const CustomUpload = lazy(() => import('pages/customUpload'));
const StudentUpload = lazy(() => import('pages/studentUpload'));
const ExcursionUpload = lazy(() => import('pages/excursionUpload'));
const PaymentDashboard = lazy(() => import('pages/paymentGateway'));
const AddCredit = lazy(() => import('pages/paymentGateway/AddCredit'));
// const Westpac = lazy(() => import('pages/paymentGateway/Westpac'));
const CreditCardFrame = lazy(() => import('pages/paymentGateway/CreditCardFrame'));
const PaymentSuccess = lazy(() => import('pages/paymentGateway/PaymentSuccess'));
const PaymentCancel = lazy(() => import('pages/paymentGateway/PaymentCancel'));
const PaymentError = lazy(() => import('pages/paymentGateway/PaymentError'));
const DataMigration = lazy(() => import('pages/dataMigration'));
const PaypalWebview = lazy(() => import('pages/paymentGateway/PaypalWebview'));
const StripeWebview = lazy(() => import('pages/paymentGateway/StripeWebview'));
const DummyPage = lazy(() => import('pages/paymentGateway/DummyPage'));

export default [
  {
    path: '/login/*', // This is the landing URL if API throws 401
    component: ExpiredPage,
  },
  {
    path: '/error/*',
    component: ErrorPage,
  },
  {
    path: '/plugins/edit/*',
    private: true,
    component: SpreadSheet,
    layout: 'DashboardLayout',
    breadcrumbPath: [{ label: 'Manage' }, { label: 'Plugins', path: '/plugins' }],
    heading: {
      title: 'Plugin Details',
      subTitle: 'View and manage plugin details',
    },
    shouldFetchHelpTexts: false,
    shouldFetchPermissions: false,
  },
  {
    path: '/sentral-activate',
    private: true,
    component: SentralActivate,
    layout: 'DashboardLayout',
    shouldFetchHelpTexts: false,
    shouldFetchPermissions: false,
  },
  {
    path: '/sentral/*',
    private: true,
    component: SentralDashboard,
    shouldFetchHelpTexts: false,
    shouldFetchPermissions: false,
  },
  {
    path: '/sso/success',
    private: true,
    component: SSO,
    shouldFetchHelpTexts: false,
    shouldFetchPermissions: false,
  },
  {
    path: '/ba_sso/success/*',
    private: true,
    component: BA_SSO,
    shouldFetchHelpTexts: false,
    shouldFetchPermissions: false,
  },
  {
    path: '/ba_sso/login/*',
    private: true,
    component: BA_SSO_LOGIN,
    shouldFetchHelpTexts: false,
    shouldFetchPermissions: false,
  },
  {
    path: '/plugins/bulkupload/*',
    private: true,
    component: BulkUpload,
    shouldFetchHelpTexts: false,
    shouldFetchPermissions: false,
  },
  {
    path: '/plugins/customUpload/*',
    private: true,
    component: CustomUpload,
    shouldFetchHelpTexts: false,
    shouldFetchPermissions: false,
  },
  {
    path: '/plugins/bulkaction/*',
    private: true,
    component: StudentUpload,
    shouldFetchHelpTexts: false,
    shouldFetchPermissions: false,
  },
  {
    path: '/plugins/excursionUpload/*',
    private: true,
    component: ExcursionUpload,
    shouldFetchHelpTexts: false,
    shouldFetchPermissions: false,
  },
  {
    path: '/tass-activate',
    private: true,
    component: TassActivate,
    layout: 'DashboardLayout',
    shouldFetchHelpTexts: false,
    shouldFetchPermissions: false,
  },
  {
    path: '/tass/*',
    private: true,
    component: TassDashboard,
    shouldFetchHelpTexts: false,
    shouldFetchPermissions: false,
  },
  {
    path: '/wonde-activate',
    private: true,
    component: WondeActivate,
    layout: 'DashboardLayout',
    shouldFetchHelpTexts: false,
    shouldFetchPermissions: false,
  },
  {
    path: '/wonde/*',
    private: true,
    component: WondeDashboard,
    shouldFetchHelpTexts: false,
    shouldFetchPermissions: false,
  },
  {
    path: '/plugins/paymentgateway/*',
    private: true,
    component: PaymentDashboard,
    shouldFetchHelpTexts: false,
    shouldFetchPermissions: false,
  },
  {
    path: '/payment/add-credit',
    private: true,
    component: AddCredit,
    shouldFetchHelpTexts: false,
    shouldFetchPermissions: false,
  },
  {
    path: '/payment/success',
    private: true,
    component: PaymentSuccess,
    shouldFetchHelpTexts: false,
    shouldFetchPermissions: false,
  },
  {
    path: '/payment/cancel',
    private: true,
    component: PaymentCancel,
    shouldFetchHelpTexts: false,
    shouldFetchPermissions: false,
  },

  {
    path: '/payment/error',
    private: true,
    component: PaymentError,
    shouldFetchHelpTexts: false,
    shouldFetchPermissions: false,
  },
  {
    path: '/payment/westpac',
    private: true,
    component: CreditCardFrame,
    shouldFetchHelpTexts: false,
    shouldFetchPermissions: false,
  },
  {
    path: '/datamigration-activate/*',
    private: true,
    component: DataMigration,
    shouldFetchHelpTexts: false,
    shouldFetchPermissions: false,
  },
  {
    path: '/payment/webview/paypal',
    private: true,
    component: PaypalWebview,
    shouldFetchHelpTexts: false,
    shouldFetchPermissions: false,
  },
  {
    path: '/payment/webview/stripe',
    private: true,
    component: StripeWebview,
    shouldFetchHelpTexts: false,
    shouldFetchPermissions: false,
  },
  {
    path: '/paypal/success',
    private: true,
    component: DummyPage,
    shouldFetchHelpTexts: false,
    shouldFetchPermissions: false,
  },
  {
    path: '/stripe/success',
    private: true,
    component: DummyPage,
    shouldFetchHelpTexts: false,
    shouldFetchPermissions: false,
  },
];
