const KONECT_URLS = {
    PLUGIN_SUBSCRIBER: '/plugin-subscriber/',
    ERROR_REPORT: `/source-object/error-report/`,
    PLUGIN_SETTINGS_MASTER: '/plugin-settings-master/',
    PLUGIN_SETTINGS: '/plugin-settings/',
    SOURCE_OBJECT: '/source-object/',
    PROFILE_PROCESS: '/profile-process/',
    INVOKE_EXPORT: 'invoke_export/',
  };
  
  export default KONECT_URLS;