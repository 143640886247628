// Core Imports
import GraniteAPIClient from 'granite-admin/utils/granite-client';
import KONECT_URLS from './endpoints';
import GraniteError from 'granite-admin/utils/granite-error';
import { env } from 'granite-admin/env';

const URL = env.REACT_APP_API_BASE_URL + env.REACT_APP_KONECT_API;
const HISTORY_EXPORT_URL = env.REACT_APP_EXPORT_BASE_URL;

async function getMyDetails(pk) {
  const { data } = await GraniteAPIClient(URL).get(`${KONECT_URLS.PLUGIN_SUBSCRIBER}${pk}/`);
  return data;
}

async function getPluginUploadHistory(payload) {
  const { data } = await GraniteAPIClient(URL).get(KONECT_URLS.SOURCE_OBJECT, payload);
  return data;
}

async function getErrorReport(payload) {
  const { data } = await GraniteAPIClient(URL).get(KONECT_URLS.SOURCE_OBJECT, payload);
  return data;
}

async function downloadErrorReport(fileId) {
  const { data } = await GraniteAPIClient(URL).get(`${KONECT_URLS.ERROR_REPORT}${fileId}/`);
  return data;
}

async function getSettingMasterSettings(params) {
  const { data } = await GraniteAPIClient(URL).get(KONECT_URLS.PLUGIN_SETTINGS_MASTER, params);
  return data;
}

async function getMySettings(params) {
  const { data } = await GraniteAPIClient(URL).get(KONECT_URLS.PLUGIN_SETTINGS, params);
  return data;
}

async function updateSettings(pk, payload) {
  const { data } = pk
    ? await GraniteAPIClient(URL).put(`${KONECT_URLS.PLUGIN_SETTINGS}${pk}/`, payload)
    : await GraniteAPIClient(URL).post(`${KONECT_URLS.PLUGIN_SETTINGS}`, payload);
  return data;
}

async function getHistory(params) {
  const { data } = await GraniteAPIClient(URL).get(KONECT_URLS.SOURCE_OBJECT, params);
  return data;
}

async function createProfileProcess(params) {
  try {
    if (params?.pk) {
      const { data } = await GraniteAPIClient(URL).put(`${KONECT_URLS.PROFILE_PROCESS}${params?.pk}/`, params);
      return data;
    } else {
      const { data } = await GraniteAPIClient(URL).post(`${KONECT_URLS.PROFILE_PROCESS}`, params);
      return data;
    }
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}
async function deleteMySettings(params) {
  const { data } = await GraniteAPIClient(URL).delete(`${KONECT_URLS.PLUGIN_SETTINGS}${params}/`);
  return data;
}

async function profileProcess(params) {
  const { data } = await GraniteAPIClient(URL).get(`/profile-process/`, params);
  return data;
}

async function deleteProfileProcess(pk) {
  try {
    return await GraniteAPIClient(URL).delete(`${KONECT_URLS.PROFILE_PROCESS}${pk}/`);
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}

async function getExport(payload) {
  const { data } = await GraniteAPIClient(HISTORY_EXPORT_URL).get(`invoke_export/`, payload);
  return data;
}

async function getMysubscriberSettings(plugin_code) {
  const { data } = await GraniteAPIClient(URL).get(`plugin-subscriber/me/?plugin_code=${plugin_code}`);
  return data;
}
export default {
  getMyDetails,
  getPluginUploadHistory,
  getErrorReport,
  downloadErrorReport,
  getSettingMasterSettings,
  getMySettings,
  updateSettings,
  deleteMySettings,
  getHistory,
  profileProcess,
  getExport,
  createProfileProcess,
  deleteProfileProcess,
  getMysubscriberSettings,
};
